<template>
  <v-card>
    <v-card-title>{{ $t('menu.translate') }} / {{ $t('landing.catalogEvents') }}</v-card-title>
    <v-card-text>
      <v-row
        class="match-height"
      >
        <v-col cols="12">
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-tabs v-model="pos">
                    <v-tab @click="setPosCotizador(1)">
                      {{ $t('landing.catalogs') }}
                    </v-tab>
                    <v-tab @click="setPosCotizador(2)">
                      {{ $t('landing.eventos') }}
                    </v-tab>
                  </v-tabs>

                  <v-divider></v-divider>

                  <v-tabs-items v-model="pos">
                    <v-tab-item>
                      <v-row>
                        <v-col cols="12">
                          <CatalogsList />
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item>
                      <v-row>
                        <v-col cols="12">
                          <EventsList />
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiEye,
  mdiDotsVertical,
} from '@mdi/js'
import CatalogsList from '../utils/CatalogsList.vue'
import EventsList from '../utils/EventsList.vue'

export default {
  components: {
    CatalogsList,
    EventsList,
  },
  data() {
    return {
      isDialogExport: false,
      languajeBase: null,
      languajeOther: null,
      isLoading: true,
      search: '',
      items: [],
      totalItems: 0,
      totalF: 0,
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiEye,
        mdiDotsVertical,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
      cant_sin_trans: 0,
      model: {
        slug: null,
        search: null,
      },
      pos: 0,
    }
  },
  computed: {
    ...mapState({
      languages: state => state.app.languages,
    }),
  },
  created() {
    this.pos = this.posCotizador
    this.profile()
  },
  methods: {
    ...mapMutations([
      'setPosCotizador',
    ]),
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
      if (this.user.nivel === -1) {
        this.is_nivel_propietario = true
      }
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
