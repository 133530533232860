<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <CatalogsEvents />
    </v-col>
  </v-row>
</template>

<script>
import CatalogsEvents from './tablas/CatalogsEvents.vue'

export default {
  components: {
    CatalogsEvents,
  },
}
</script>
